export enum CollectionSortEnum {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  CREATED_ASC = "CREATED_ASC",
  CREATED_DESC = "CREATED_DESC",
}

export enum CollectionDisplayEnum {
  BIG = "BIG",
  SMALL = "SMALL",
  NO_WORD = "NO_WORD",
}

import { Box } from "@mui/material"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  CollectionDisplayEnum,
  CollectionSortEnum,
} from "../../core/const/CollectionEnum"
import { CollectionSearchRequest } from "../../core/type/Request"
import { CollectionInfo } from "../../core/type/Response"
import { AssetsWrapper } from "../account/AssetsWrapper"
import { EmptyData } from "../common/EmptyData"
import { PrimaryButton } from "../common/PrimaryButton"
import { SearchWrapper } from "../common/SearchWrapper"
import { SortSelectionWrapper } from "../common/SortSelectionWrapper"
import { CollectionHeader } from "./CollectionHeader"
import { orderBy } from "lodash"
import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined"
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined"
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined"

export type CollectionDetailProps = { collections: CollectionInfo | false }

export const CollectionDetail: React.FunctionComponent<CollectionDetailProps> =
  ({ collections }) => {
    const { t } = useTranslation()
    const [searchRequest, setSearchRequest] = useState<CollectionSearchRequest>(
      {
        keyName: "",
        sortBy: CollectionSortEnum.CREATED_DESC,
        display: CollectionDisplayEnum.BIG,
      }
    )

    const getNFTs = () => {
      const sortBy = searchRequest.sortBy.toLowerCase().includes("desc")
        ? "desc"
        : "asc"
      return collections
        ? orderBy(
            collections.collectionNFT.filter(item =>
              item.metadata?.name?.toLowerCase().includes(searchRequest.keyName)
            ),
            item =>
              searchRequest.sortBy.toLowerCase().includes("name")
                ? item.metadata?.name || ""
                : item.tokenId,
            [sortBy]
          )
        : []
    }

    const sortOption = [
      {
        label: t("str_sort_newest"),
        value: CollectionSortEnum.CREATED_ASC,
      },
      {
        label: t("str_sort_oldest"),
        value: CollectionSortEnum.CREATED_DESC,
      },
      {
        label: t("str_sort_name_asc"),
        value: CollectionSortEnum.NAME_ASC,
      },
      {
        label: t("str_sort_name_desc"),
        value: CollectionSortEnum.NAME_DESC,
      },
    ]

    const displayIcon = {
      [CollectionDisplayEnum.BIG]: <WindowOutlinedIcon fontSize="small" />,
      [CollectionDisplayEnum.SMALL]: <GridOnOutlinedIcon fontSize="small" />,
      [CollectionDisplayEnum.NO_WORD]: (
        <AutoAwesomeMosaicOutlinedIcon fontSize="small" />
      ),
    }

    return (
      <>
        {collections && (
          <>
            <CollectionHeader collection={collections.collection} />
            {collections.collectionNFT.length > 0 && (
              <div className="items-center justify-between px-16 pb-10 lg:flex">
                <SearchWrapper
                  sx={{
                    "&.c-header__search": { margin: 0, maxWidth: "400px" },
                  }}
                  placeholder={t("str_search_collection_nft_placeholder")}
                  search={searchRequest.keyName}
                  setSearch={value =>
                    setSearchRequest({
                      ...searchRequest,
                      keyName: value,
                    })
                  }
                />
                <div className="mt-4 flex items-center lg:mt-0">
                  <SortSelectionWrapper
                    className="min-w-[200px]"
                    options={sortOption}
                    sortBy={searchRequest.sortBy}
                    setSortBy={value =>
                      setSearchRequest({
                        ...searchRequest,
                        sortBy: value,
                      })
                    }
                  />
                  <div className="ml-5 flex w-[120px] overflow-hidden rounded-[5px] border border-solid border-[#767676]">
                    {Object.values(CollectionDisplayEnum).map((item, index) => (
                      <Box
                        sx={{
                          aspectRatio: "1 / 1",
                          "&:not(:last-of-type)": {
                            borderRight: "1px solid #767676",
                          },
                          backgroundColor:
                            searchRequest.display === item
                              ? "primary.main"
                              : "",
                          color:
                            searchRequest.display === item
                              ? "primary.contrastText"
                              : "",
                          "&:hover": {
                            backgroundColor:
                              searchRequest.display !== item
                                ? "primary.light"
                                : "",
                          },
                        }}
                        key={index}
                        className="flex w-1/3 cursor-pointer items-center justify-center"
                        onClick={() =>
                          setSearchRequest({
                            ...searchRequest,
                            display: item,
                          })
                        }
                      >
                        {displayIcon[item]}
                      </Box>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {collections.collectionNFT.length > 0 ? (
              getNFTs().length > 0 ? (
                <div className="px-16 pb-28">
                  <div className="flex flex-wrap align-top">
                    {getNFTs().map((item, index) => (
                      <AssetsWrapper
                        item={item}
                        key={index}
                        onClick={() =>
                          navigate(`/${item.address}/${item.tokenId}`)
                        }
                        searchRequest={searchRequest}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <EmptyData
                  height="70vh"
                  img={"/images/search_no_found.png"}
                  message={t("str_search_empty_message")}
                />
              )
            ) : (
              <EmptyData
                height="70vh"
                img={"/images/empty_nft.png"}
                message={t("str_nft_empty_message")}
              >
                <PrimaryButton
                  sx={{
                    height: 60,
                    width: "100%",
                    fontSize: 21,
                    marginTop: 5,
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => navigate("/create_nft")}
                >
                  {t("str_create_nft")}
                </PrimaryButton>
              </EmptyData>
            )}
          </>
        )}
      </>
    )
  }

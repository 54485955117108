import React from "react"
import { CollectionSortEnum } from "../../core/const/CollectionEnum"
import { Option } from "../../core/type/Option"

export type SortSelectionWrapperProps = {
  className?: string
  options: Array<Option<CollectionSortEnum>>
  sortBy: CollectionSortEnum
  setSortBy: (value: CollectionSortEnum) => void
}

export const SortSelectionWrapper: React.FunctionComponent<SortSelectionWrapperProps> =
  ({ className = "", options, sortBy, setSortBy }) => {
    return (
      <div className={className}>
        <select
          value={sortBy}
          className="h-10 w-full cursor-pointer rounded-[3px] border border-[#767676] px-4"
          onChange={e => setSortBy(e.target.value as CollectionSortEnum)}
        >
          {options.map((item, index) => (
            <option value={item.value} key={index}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    )
  }

import { Box, useTheme } from "@mui/material"
import React from "react"

export type EmptyDataProps = {
  height?: string
  img: string
  message: string
  children?: React.ReactNode
}

export const EmptyData: React.FunctionComponent<EmptyDataProps> = ({
  height = "80vh",
  img,
  message,
  children,
}) => {
  const { palette } = useTheme()
  return (
    <Box
      sx={{ color: palette.grey["100"], height: height }}
      className="flex w-full flex-col items-center justify-center"
    >
      <img className="h-1/3 w-auto" src={img} alt="empty_data" />
      <Box sx={{ color: palette.text.secondary }} className="text-h3 mt-1">
        {message}
      </Box>
      {children}
    </Box>
  )
}

import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Collection } from "../../components/collection/Collection"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { getCustomConfig } from "../../core/utils/getCustomConfig"

export type CollectionProps = { id: string } & FunctionComponent

export default function CollectionPage(props: CollectionProps): JSX.Element {
  const collectionID = props["id"]
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo
        title={`${t("str_create_collection")} | ${
          getCustomConfig().title_suffix
        }`}
        favicon={getCustomConfig().favicon}
      />
      <Collection id={collectionID} />
    </Layout>
  )
}

import { Box } from "@mui/material"
import React from "react"
import { NFT } from "../../core/api"
import { CollectionDisplayEnum } from "../../core/const/CollectionEnum"
import { CollectionSearchRequest } from "../../core/type/Request"
import { replaceEndOfLineToSpace } from "../../core/utils/convertEndOfLine"

export type AssetsWrapperProps = {
  item: NFT
  onClick: () => void
  searchRequest?: CollectionSearchRequest
}

export const AssetsWrapper: React.FunctionComponent<AssetsWrapperProps> = ({
  item,
  onClick,
  searchRequest,
}) => {
  return (
    <Box
      sx={{ "&:hover": { borderColor: "primary.main", borderStyle: "solid" } }}
      className={`c-nft__item cursor-pointer hover:border ${
        searchRequest ? searchRequest.display : ""
      }`}
      onClick={onClick}
    >
      <div className="c-nft__item--link">
        <div className="c-nft__item--media rounded-[10px]">
          <img
            className="mb-0"
            src={item.image}
            alt={item.metadata?.name || ""}
          />
        </div>
        {(!searchRequest ||
          searchRequest.display !== CollectionDisplayEnum.NO_WORD) && (
          <>
            <Box className="c-nft__item--cat" sx={{ color: "primary.main" }}>
              {item.metadata?.collectionName || ""}
            </Box>
            <div className="c-nft__item--ttl">{item.metadata?.name || ""}</div>
            <div className="c-nft__item--txt">
              {replaceEndOfLineToSpace(item.metadata?.description || "")}
            </div>
          </>
        )}
      </div>
    </Box>
  )
}

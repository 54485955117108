import { Box, useTheme } from "@mui/material"
import React from "react"
import { useOrientation } from "react-use"
import { Collection } from "../../core/api"
import { formatEndOfLineToHtml } from "../../core/utils/convertEndOfLine"

export type CollectionHeaderProps = { collection: Collection }

export const CollectionHeader: React.FunctionComponent<CollectionHeaderProps> =
  ({ collection }) => {
    const { type } = useOrientation()
    const { palette, breakpoints } = useTheme()

    return (
      <>
        <Box
          className="relative flex w-full flex-col justify-end bg-cover bg-center bg-no-repeat"
          sx={{
            aspectRatio: type === `landscape-primary` ? "4 / 1" : "3 / 2",
            backgroundImage: `url(${
              type === `landscape-primary`
                ? collection.banner_image
                : collection.feature_image
            })`,
          }}
        >
          <Box
            className="absolute z-10 flex w-32 items-center justify-center overflow-hidden rounded-full border border-solid border-gray-300 bg-white lg:-bottom-6 lg:left-16"
            sx={{
              aspectRatio: "1 / 1",
              [breakpoints.down("lg")]: {
                left: "calc(50% - 64px)",
                bottom: "-40px",
              },
            }}
          >
            <img
              className="mb-0 max-h-full max-w-full object-contain"
              src={collection.logo_image}
              alt="logo"
            />
          </Box>
        </Box>
        <Box
          className="flex w-full flex-col items-center px-10 py-14 lg:items-start"
          sx={{ backgroundColor: palette.background.paper }}
        >
          <div className="text-h3 mb-3 font-normal lg:pl-16">
            {collection.name}
          </div>
          <Box className="text-h5 w-full text-center font-light lg:pl-16 lg:text-left">
            {formatEndOfLineToHtml(collection.descript || "")}
          </Box>
        </Box>
      </>
    )
  }

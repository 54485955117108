import React from "react"

export function replaceEndOfLineToSpace(word: string): string {
  return word.replace(/\n/g, " ")
}

export function formatEndOfLineToHtml(word: string): Array<JSX.Element> {
  const words = word.split(/\n/g)
  return words.map((w, index) => (
    <span key={index} className="block">
      {w || <>&nbsp; </>}
    </span>
  ))
}

import React from "react"
import { MainLayout } from "../common/MainLayout"
import { CollectionContainer } from "./CollectionContainer"

export type CollectionProps = { id: string }

export const Collection: React.FunctionComponent<CollectionProps> = ({
  id,
}) => {
  return (
    <MainLayout>
      <CollectionContainer id={id} />
    </MainLayout>
  )
}

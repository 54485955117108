import React from "react"
import { useAsync } from "react-use"
import { AppContext } from "../../AppContext"
import AlertContainer from "../../core/store/AlertContainer"
import WalletContainer from "../../core/store/WalletContainer"
import { LoadingWrapper } from "../common/LoadingWrapper"
import { CollectionDetail } from "./CollectionDetail"

export type CollectionContainerProps = { id: string }

export const CollectionContainer: React.FunctionComponent<CollectionContainerProps> =
  ({ id }) => {
    const { pushAlert } = AlertContainer.useContainer()
    const { walletAddress } = WalletContainer.useContainer()

    const collectionData = useAsync(async () => {
      if (id && Number.isFinite(+id)) {
        AppContext.apiExecutor.collectionTrendPut({ collectionID: +id }).catch()
        const res = await AppContext.apiExecutor.nftSearchGet(
          ["item"],
          [""],
          walletAddress
        )

        const currentCollection = res.data.collection?.find(
          collect => collect.collectionID === +id
        )

        if (!currentCollection) {
          pushAlert({
            message: "can't found collection",
            severity: "error",
          })
          return false
        }

        const collectionNFT = (res.data.nft || []).filter(
          nft => nft.metadata?.collectionID === +id
        )

        return {
          collection: currentCollection,
          collectionNFT: collectionNFT,
        }
      } else {
        pushAlert({
          message: "can't found collection",
          severity: "error",
        })
        return false
      }
    }, [])

    return (
      <LoadingWrapper
        state={collectionData}
        keepLoading={data => !data}
        render={data => <CollectionDetail collections={data} />}
      />
    )
  }
